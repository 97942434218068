<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">Add/Edit Presentation Details</h1>
    </page-header>
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <h4 v-if="churchName !== ``">
          {{ churchName }}
          <div class="change-link" @click="showChurchDropdown">{{ translations.tcChange }}</div>
        </h4>
        <h1 class="sm mb-4">
          {{ translations.tcAddEditChurchPresentation }}
        </h1>
        <div class="body">
          <b-form>
            <b-form-group class="form-element">
              <span v-if="churchName === ``">
                <div class="font-style-2">{{ translations.tcSelectChurch }}</div>
                <div class="g-row">
                  <div class="g-col-1 d-flex">
                    <b-form-select v-model="selectedChurchKey" :options="selectChurch"
                      @change="handleChurchChange($event)" class="form-control flex-1 g-select">
                    </b-form-select>
                  </div>
                  <div class="g-col-2"></div>
                </div>
              </span>
              <div class="font-style-2">
                {{ translations.tcPresentationDate }}
              </div>
              <div class="g-row">
                <div class="g-col-1 d-flex date-select-1">
                  <b-form-datepicker id="presentationDate" v-model="presentationDate" class="mb-2 contact_date"
                    :locale="prefCulture" :label-help="translations.tcCalHelp"
                    :label-no-date-selected="translations.tcDatePickerPlaceholder"
                    :placeholder="translations.tcDatePickerPlaceholder" :show-decade-nav="showDecadeNav"
                    :hide-header="hideHeader">
                    <template #button-content style="padding: 12px">
                      <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                    </template>
                  </b-form-datepicker>
                </div>
              </div>
            </b-form-group>
          </b-form>
        </div>
      </section>
      <section v-if="userSelectedChurchKey.length > 0" class="section-2 bg-block mb-4">
        <header class="toggle-header mb-3">
          <h2 class="font-style-1">
            {{ translations.tcPresentationTimesSpeaker }}
            <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcTooltip">
              <i-tooltip />
            </span>
          </h2>
          <ToggleArrow @click="Section2 = !Section2" section="Section2" :status="Section2"></ToggleArrow>
        </header>
        <div class="body" v-if="Section2">
          <b-form>
            <b-form-group class="form-element">
              <div class="font-style-2">{{ translations.tcSelectTime }}</div>
              <div class="g-row">
                <div class="g-col-1 d-flex">
                  <b-form-select v-model="selectedServiceKey" :options="availableServices"
                    class="form-control flex-1 g-select w-auto mr-2" @change="handleServiceTimeChange">
                    <template slot="first">
                      <option :value="null" disabled>
                        {{ translations.tcSelectTime }}
                      </option>
                    </template>
                  </b-form-select>
                  <b-button variant="primary" class="flex-0 mr-0 btn-pl-xsm"
                    @click="showModal($event, 'newServiceModal')">
                    {{ translations.tcAddNewTime }}
                  </b-button>
                </div>
                <div class="g-col-2"></div>
              </div>
            </b-form-group>
            <div v-if="newPresentations.length > 0">
              <div v-for="(inst, indx) in newPresentations" :key="indx">
                <presentation-time :pt_props="inst" @speakerFindClicked="showModal($event, 'personSearchModal')"
                  @remove_presentation_time="remove_presentation_time($event)"
                  :i18n="translations.components"></presentation-time>
              </div>
              <strong> * = {{ translations.tcQualifiedForSpeaking }} </strong>
            </div>
          </b-form>
        </div>
      </section>
      <section v-if="userSelectedChurchKey && userSelectedChurchKey.length > 0" class="section-3 bg-block mb-4">
        <header class="toggle-header mb-3">
          <h2 class="font-style-1">
            {{ translations.tcPresentationDetails }}
          </h2>
        </header>
        <div class="body">
          <b-form ref="presentationDetailsForm">
            <b-form-group class="row form-element">
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <div class="font-style-2">
                    {{ translations.tcSpeakerArrivalTime }}
                  </div>
                  <div class="g-row mb-0 mb-sm-3">
                    <div class="g-col-1 mb-3 mb-sm-0">
                      <div class="time-select-1">
                        <b-form-timepicker v-model="selectedArrivalTime" minutes-step="5" :locale="prefCulture"
                          :label-close-button="translations.tcClose"
                          :label-no-time-selected="translations.tcNoTimeSelected">
                        </b-form-timepicker>
                      </div>
                    </div>
                  </div>
                  <div class="font-style-2">
                    {{ translations.tcSelectPrayerPartnerLabel }}
                  </div>
                  <div class="g-row mb-0 mb-sm-3">
                    <div class="g-col-1 d-flex mb-3 mb-sm-0">
                      <b-form-select v-model="selectedPartnerKey" :options="[...all_presenters_list, ...searchedPartner]"
                        class="form-control col-left-input g-select flex-0 mr-3">
                        <template slot="first">
                          <option :value="null" disabled>
                            {{ translations.tcSelectPrayerPartner }}
                          </option>
                          <option :value="'00000000-0000-0000-0000-000000000000'">
                            {{ translations.tcNone }}
                          </option>
                        </template>
                      </b-form-select>
                      <b-button :name="`partnerFindButton`" variant="primary" class="flex-1 btn-pl-xsm"
                        @click="showModal($event, 'personSearchModal')">
                        {{ translations.tcFind }}
                      </b-button>
                    </div>
                  </div>
                  <div class="font-style-2">
                    {{ translations.tcContactedBy }}
                  </div>
                  <div class="g-row mb-0 mb-sm-3">
                    <div class="g-col-1 d-flex mb-3 mb-sm-0">
                      <b-form-select v-model="selectedContactKey" :options="[...all_presenters_list, ...searchedContact]"
                        class="form-control col-left-input g-select flex-0 mr-3">
                        <template slot="first">
                          <option :value="null" disabled>
                            {{ translations.tcSelectContactBy }}
                          </option>
                        </template>
                      </b-form-select>
                      <b-button :name="`contactFindButton`" variant="primary" class="flex-1 btn-pl-xsm"
                        @click="showModal($event, 'personSearchModal')">
                        {{ translations.tcFind }}
                      </b-button>
                    </div>
                  </div>
                  <div class="g-row">
                    <div class="g-col-1 mb-3 mb-sm-0">
                      <div class="font-style-2">
                        {{ translations.tcSpeakerShouldContact }}
                      </div>
                      <b-form-input id="type-text" v-model="shouldContact"
                        class="form-control col-left-input flex-1 g-select" type="text">
                      </b-form-input>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12" style="margin-top: 20px">
                  <div class="g-row mb-0 mb-sm-3">
                    <div class="g-col-1 d-flex align-items-center mb-3 mb-sm-0">
                      <h3 class="flex-1 mr-3 text-right sm-text-left font-style-2">
                        {{ translations.tcBulletinsRequired }}
                      </h3>
                      <!-- For `state` props, return null if valid so bootstrap doesn't add green class -->
                      <b-form-input id="type-number3" v-model.number="bulletinsRequired" class="flex-0" type="number"
                        :state="bulletinsRequired >= 0 && null" min="0"></b-form-input>
                    </div>
                  </div>
                  <div class="g-row mb-0 mb-sm-3">
                    <div class="g-col-1 d-flex align-items-center mb-3 mb-sm-0">
                      <h3 class="flex-1 mr-3 text-right sm-text-left font-style-2">
                        {{ translations.tcAttendance }}
                      </h3>
                      <b-form-input id="type-number4" v-model.number="attendance" class="flex-0" type="number" min="0"
                        :state="attendance >= 0 && null">
                      </b-form-input>
                    </div>
                  </div>
                  <div class="g-row mb-0 mb-sm-3">
                    <div class="g-col-1 d-flex align-items-center mb-3 mb-sm-0">
                      <h3 class="flex-1 mr-3 text-right sm-text-left font-style-2">
                        {{ translations.tcOfferingAmount }}
                      </h3>
                      <h3 class="font-style-2">$</h3>
                      <b-form-input id="type-number5" v-model.number="offering" class="flex-0" type="number" min="0"
                        pattern="[0-9]*[.]?[0-9]" step=".01" :state="offering >= 0 && null">
                      </b-form-input>
                    </div>
                  </div>
                  <div class="g-row">
                    <div class="g-col-1 d-flex align-items-center">
                      <h3 class="flex-1 mr-3 text-right sm-text-left font-style-2">
                        {{ translations.tcOfferingAgreeable }}
                      </h3>
                      <b-form-checkbox id="checkbox-1" v-model="offeringAgreeable" name="checkbox-1" value="true"
                        unchecked-value="false"></b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="g-row">
                <div class="g-col-1 mb-3 mb-sm-0">
                  <strong> * = {{ translations.tcQualifiedForSpeaking }} </strong>
                </div>
              </div>
            </b-form-group>
          </b-form>
        </div>
      </section>
      <section v-if="userSelectedChurchKey.length > 0" class="section-4 bg-block mb-4">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcOtherInformation }}</h2>
        </header>
        <div class="body">
          <textarea name="other" v-model="otherInfo" class="w-100"></textarea>
        </div>
      </section>
      <section v-if="userSelectedChurchKey.length > 0" class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button variant="primary" @click="handleSaveClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
              {{ translations.tcSave }}
            </b-button>
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">
              {{ translations.tcCancel }}
            </b-button>
          </div>
        </div>
      </section>
    </page-body>
    <AddServiceTime :church_name="churchName" :church_key="selectedChurchKey" :service_being_edited="service_to_edit"
      @timeAddSuccess="handleTimeAddSuccess($event)"></AddServiceTime>
    <PersonSearch :cmp_org_key="this.churchCampKey" @selectionMade="applyFoundPerson($event)"></PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AddServiceTime from '@/components/AddServiceTime.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import { numericMixin } from '@/mixins/numericMixin'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import PresentationTime from '@/components/PresentationTime.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'add-church-presentation',
  mixins: [numericMixin, translationMixin],
  data() {
    return {
      attendance: null,
      activeSearchKey: null,
      availableServices: [],
      bulletinsRequired: null,
      findButton: '',
      hideHeader: true,
      nagKey: null,
      newPresentations: [],
      offering: null,
      offeringAgreeable: false,
      otherInfo: '',
      presentationDate: null,
      removedServices: [],
      Section2: true,
      selectChurch: [],
      selectedChurchKey: null,
      selectedContactKey: null,
      selectedContactMxiKey: null,
      searchedContact: [],
      selectedServiceKey: null,
      searchedPartner: [],
      selectedPartnerKey: null,
      selectedPartnerMxiKey: null,
      searchedSpeaker: [],
      selectedSpeakerKey: null,
      selectedArrivalTime: null,
      service_to_edit: null,
      serviceTime: '',
      shouldContact: null,
      showDecadeNav: true,
      statusKey: null,
      translations: {},
      usedServices: [],
    }
  },
  methods: {
    ...mapActions({
      clearSinglePresentation: 'churchMinistry/clearSinglePresentation',
      getAllPresentersByCamp: 'churchMinistry/getAllPresentersByCamp',
      loadChurches: 'churchMinistry/loadChurches',
      loadPresentationsAndServiceTimes: 'churchMinistry/loadPresentationsAndServiceTimes',
      loadSinglePresentation: 'churchMinistry/loadSinglePresentation',
      savePresentation: 'churchMinistry/savePresentation',
      setLoadingStatus: 'menu/setLoadingStatus',
      setPresentationEditKey: 'churchMinistry/setPresentationEditKey',
      setSelectedChurchKey: 'user/setSelectedChurchKey',
    }),
    async handleChurchChange(evt) {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.setSelectedChurchKey(evt),
        await this.getAllPresentersByCamp(),
        await this.loadPresentationsAndServiceTimes(),
      ]).then(() => {
        this.availableServices = this.churchServices
        this.translateServiceTimeDropdown()

        this.setLoadingStatus(false)
      })
    },
    async handleCancelClick() {
      this.resetData()
      this.$router.push('/programs/cm/presentations')
    },
    async handleSaveClick() {
      let errors = []
      const numberErrorText = this.translations.tcErrorNoSpecialChar
      const { presentationDetailsForm } = this.$refs

      if (!presentationDetailsForm.checkValidity()) {
        presentationDetailsForm.forEach((element) => {
          if (!element.validity.valid) {
            let hasErrorAlready = errors.findIndex((e) => e === numberErrorText)
            if (element.type === 'number' && hasErrorAlready < 0) {
              presentationDetailsForm.querySelector(`#${element.id}`).focus()
              errors.push(numberErrorText)
            }
          }
        })
        return this.$swal({
          icon: 'error',
          text: `${this.translations.tcErrorFixValidationErrors} ${errors.join(' ')}`,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      }
      if (!this.presentationDate) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorSelectDate,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      } else {
        let result = true
        let response = true
        // gather & validate data
        let payload = {
          key: this.singlePresentation !== null ? this.singlePresentation.meeting_key : null, //  null if new, otherwise use key
          contact: this.selectedContactKey,
          start_date: this.presentationDate,
          start_time: null,
          note: this.otherInfo,
          status_key: this.statusKey,
          nag_key: this.nagKey,
          arrival_time: this.selectedArrivalTime === null ? '' : this.formatTimeString(this.selectedArrivalTime),
          bulletins: this.bulletinsRequired,
          attendance: this.attendance,
          offering: this.offering,
          offering_flag: this.offeringAgreeable,
          times: [...this.newPresentations, ...this.removedServices],
        }
        let participants = {
          partner: this.selectedPartnerKey,
          partnerMxiKey: this.selectedPartnerMxiKey,
          contactedBy: this.selectedContactKey,
          contactedByMxiKey: this.selectedContactMxiKey,
          nag_key: this.nagKey,
          shouldContact: this.shouldContact,
        }
        // submit data
        await Promise.all([
          this.setLoadingStatus(true),
          (response = await this.savePresentation({
            payload: payload,
            participants: participants,
          })),
        ]).then(() => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: response ? 'success' : 'error',
            text: response ? this.translations.tcPresentationSaved : this.translations.tcErrorSavingData,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((result) => {
            if (response) {
              this.resetData()
              this.$router.push({ name: 'service-times-presentations' })
            }
          })
        })
      }
    },
    handleTimeAddSuccess(evt) {
      this.$swal({
        icon: evt ? 'success' : 'error',
        text: evt ? this.translations.tcServiceTimeAdded : this.translations.tcErrorSavingTime,
        confirmButtonText: this.translations.tcOk || 'Ok',
      }).then(() => {
        Promise.all([this.setLoadingStatus(true), this.loadPresentationsAndServiceTimes()]).then(() => {
          this.availableServices = this.churchServices
          this.translateServiceTimeDropdown()
          this.setLoadingStatus(false)
        })
      })
    },
    async handleServiceTimeChange() {
      if (this.selectedServiceKey === null || this.usedServices.some((u) => u.value === this.selectedServiceKey)) {
        this.$swal({
          icon: 'error',
          text:
            this.selectedServiceKey === null
              ? this.translations.tcSelectServiceTime
              : this.translations.tcErrorServiceTimeSelected,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      } else {
        let used = this.availableServices.find((q) => q.value === this.selectedServiceKey)
        this.usedServices.push(used)
        this.availableServices.findIndex((x) => x.value === this.selectedServiceKey) > -1
          ? this.availableServices.splice(
            this.availableServices.findIndex((x) => x.value === this.selectedServiceKey),
            1
          )
          : null
        this.serviceTime = used.text
        await Promise.all([
          this.newPresentations.push({
            serviceTime: this.serviceTime,
            serviceTimeKey: this.selectedServiceKey,
            serviceType: this.churchServiceTypeList,
            speakerList: this.all_gideon_presenters_list,
            attireList: this.churchServiceAttireList,
            removeKey: this.selectedServiceKey,
            searchedSpeaker: this.searchedSpeaker,
            speakerKey: this.selectedSpeakerKey,
            attire: null,
            mediaTypeKey: null,
            speakerMinutes: null,
          }),
        ])
        this.selectedServiceKey = null
      }
    },
    async applyFoundPerson(evt) {
      switch (this.findButton) {
        case 'partnerFindButton':
          this.searchedPartner.push({ value: evt.ind_key, text: evt.name_text })
          this.selectedPartnerKey = evt.ind_key
          break

        case 'contactFindButton':
          this.searchedContact.push({ value: evt.ind_key, text: evt.name_text })
          this.selectedContactKey = evt.ind_key
          break

        default:
          // locate the active newPresentation -- here, findButton is a newPresentations object
          let nps = this.newPresentations.find((np) => np.removeKey === this.findButton.removeKey)
          nps.searchedSpeaker.push({ value: evt.ind_key, text: evt.name_text })
          nps.speakerKey = evt.ind_key
          nps.speakerName = evt.name_text
          break
      }
    },
    async remove_presentation_time(evt) {
      const searchItem = (t) => t.removeKey === evt
      const pres = this.newPresentations.find(searchItem)
      pres.cts_delete_flag = true
      if (pres.cts_key) {
        this.removedServices.push({
          attire: pres.attire,
          removeKey: pres.removeKey,
          cts_key: pres.cts_key,
          cts_delete_flag: true,
          mediaTypeKey: pres.mediaTypeKey,
          speakerKey: pres.speakerKey,
          speakerMinutes: pres.speakerMinutes,
          serviceTimeKey: pres.removeKey,
        })
      }
      const idx = this.newPresentations.findIndex(searchItem)
      this.newPresentations.splice(idx, 1)

      let avs = this.usedServices.find((q) => q.value === evt)
      this.availableServices.push(avs)
      this.availableServices.sort((a, b) => (a.text < b.text ? -1 : 1))
      this.usedServices.findIndex((x) => x.value === evt) > -1
        ? this.usedServices.splice(
          this.usedServices.findIndex((x) => x.value === evt),
          1
        )
        : null

      if (this.availableServices.length == 1) {
        this.selectedServiceKey = null
      }
    },
    async showChurchDropdown() {
      this.newPresentations = []
      this.availableServices = []
      this.selectedChurchKey = null
      await this.setSelectedChurchKey('')
    },
    async getSinglePresentation() {
      this.newPresentations = []
      await this.loadSinglePresentation(this.presentationEditKey)
      this.presetDate = new Date(Date.parse(this.singlePresentation.presentation_date))
      this.selectedChurchKey = this.singlePresentation.church_key
      await this.handleChurchChange(this.singlePresentation.church_key)
      await Promise.all([
        (this.bulletinsRequired = this.singlePresentation.mtg_bulletins_required_number),
        this.setSelectedArrivalTime(),
        this.getSelectedPartnerKeys(),
        this.getSelectedContactKeys(),
        (this.attendance = this.singlePresentation.mtg_estimated_attendance),
        (this.nagKey = this.singlePresentation.mtg_nag_key),
        (this.offering = this.singlePresentation.mtg_offering_amount),
        (this.offeringAgreeable = this.singlePresentation.mtg_offering_agreeable_flag),
        (this.otherInfo = this.singlePresentation.note),
        (this.presentationDate = this.singlePresentation.presentation_date),
        (this.shouldContact = this.singlePresentationForDisplay.panel_three.should_contact),
        (this.statusKey = this.singlePresentation.mtg_mts_key),
        (this.newPresentations = this.singlePresentation.mtg_times.map((tm) => {
          this.usedServices.push({ text: tm.service_time, value: tm.time_key })
          this.availableServices.findIndex((x) => x.value === tm.time_key) > -1
            ? this.availableServices.splice(
              this.availableServices.findIndex((x) => x.value === tm.time_key),
              1
            )
            : null
          // If the currently assigned speaker is outside the camp
          // they won't be in the presenters list to be pre-selected
          // If this is the case, add them to the searchedSpeaker array
          const searchedSpeaker = []
          const currentSpeakerInList = this.all_gideon_presenters_list.some((pres) => {
            return pres.value === tm.cts_speaker_ind_key
          })
          if (!currentSpeakerInList) {
            const currentSpeakerToAdd = {
              value: tm.cts_speaker_ind_key,
              text: tm.cts_speaker_name,
            }
            searchedSpeaker.push(currentSpeakerToAdd)
          }

          return {
            cts_key: tm.cts_key,
            serviceTime: tm.service_time,
            serviceTimeKey: tm.time_key,
            serviceType: this.churchServiceTypeList,
            speakerList: this.all_gideon_presenters_list,
            attireList: this.churchServiceAttireList,
            removeKey: tm.time_key,
            searchedSpeaker,
            speakerKey: tm.cts_speaker_ind_key,
            speakerName: tm.cts_speaker_name,
            attire: tm.attire_key,
            mediaTypeKey: tm.media_key,
            speakerMinutes: tm.cts_speaking_time,
          }
        })),
      ])
    },
    async setSelectedArrivalTime() {
      if (!!this.singlePresentation.mtg_church_presenter_arrival_time) {
        this.selectedArrivalTime = this.formatTimeToMilitary(this.singlePresentation.mtg_church_presenter_arrival_time)
      }
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    async getSelectedPartnerKeys() {
      let spkeys = this.singlePresentation.mtg_roles.find((pp) => {
        return pp.role === 'Prayer Partner'
      })
      this.selectedPartnerKey = !spkeys ? null : spkeys.mxi_ind_key
      this.selectedPartnerMxiKey = !spkeys ? null : spkeys.mxi_key
      if (
        [...this.all_presenters_list, ...this.searchedPartner].findIndex((ap) => ap.value === this.selectedPartnerKey) <
        0 &&
        !!this.selectedPartnerKey
      ) {
        this.searchedPartner.push({ value: this.selectedPartnerKey, text: spkeys.name })
      }
    },
    async getSelectedContactKeys() {
      let cnkeys = this.singlePresentation.mtg_roles.find((pp) => {
        return pp.role.toLowerCase() === 'contacted by'
      })
      this.selectedContactKey = !cnkeys ? null : cnkeys.mxi_ind_key
      this.selectedContactMxiKey = !cnkeys ? null : cnkeys.mxi_key
    },
    async resetData() {
      await this.setPresentationEditKey(null)
      await this.clearSinglePresentation()
    },

    translateServiceTimeItem(fieldName, translationNode, toLowerCase) {
      const lookupVal = this.convertValForTranslation(fieldName, toLowerCase)

      return this.translations.commonText[translationNode].hasOwnProperty(lookupVal)
        ? this.translations.commonText[translationNode][lookupVal]
        : fieldName
    },

    async translateServiceTimeDropdown() {
      if (!this.availableServices || this.availableServices.length < 1) return false

      this.availableServices = this.availableServices.map((svc) => {
        const serviceTimeList = svc.text.split(/( - |,)/g) // split on ' - ' and ','

        const translatedServiceType = this.translateServiceTimeItem(
          serviceTimeList[0].trim(),
          'church-service-type',
          false
        )
        const translatedServiceDay = this.translateServiceTimeItem(serviceTimeList[2].trim(), 'days')

        return { ...svc, text: `${translatedServiceType} - ${translatedServiceDay}, ${serviceTimeList[4].trim()}` }
        //returns string like: 'Worship PM - Sunday, 04:00PM'
      })
    },
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.clearSinglePresentation(),
          await this.getTranslations(),
          await this.getComponentTranslations('presentation-time'),
          await this.getComponentTranslations('common.church-service-type', 'common.days'),
        ]).then((results) => {
          this.stripReadableText(results[3], 'text')
          const translatedText = {
            ...results[2],
            common: { ...this.translations.common },
          }
          this.$set(this.translations, 'components', translatedText)

          this.availableServices = this.churchServices
          this.translateServiceTimeDropdown()
        })
        if (!this.userSelectedChurchKey) {
          await Promise.all([await this.loadChurches()]).then(() => {
            this.selectChurch = [...this.selectChurch, ...this.churchesForSelection]
          })
        } else {
          await Promise.all([
            await this.loadChurches(),
            await this.handleChurchChange(this.userSelectedChurchKey),
          ]).then(() => {
            this.selectedChurchKey = this.userSelectedChurchKey
            this.selectChurch = [...this.selectChurch, ...this.churchesForSelection]
          })
        }
        if (!!this.presentationEditKey && this.isGuid(this.presentationEditKey)) {
          await this.getSinglePresentation()
        }
      } catch (e) {
        console.error('Error during page_load(), ', e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  async created() {
    await this.page_load()
  },
  computed: {
    ...mapGetters({
      all_gideon_presenters: 'churchMinistry/all_gideon_presenters',
      all_presenters: 'churchMinistry/all_presenters',
      churchCampKey: 'user/userCampKey',
      churchDetail: 'churchMinistry/churchDetail',
      churches: 'churchMinistry/churches',
      churchesForSelection: 'churchMinistry/churchesForSelection',
      churchServiceAttireList: 'churchMinistry/churchServiceAttireList',
      churchServices: 'churchMinistry/churchServices',
      churchServiceTypeList: 'churchMinistry/churchServiceTypeList',
      prefCulture: 'user/userPreferredCulture',
      presentationEditKey: 'churchMinistry/presentation_edit_key',
      presenterList: 'churchMinistry/presenterList',
      singlePresentation: 'churchMinistry/single_presentation',
      singlePresentationForDisplay: 'churchMinistry/single_presentation_for_display',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
    }),
    churchName() {
      return !!this.userSelectedChurchKey
        ? this.churches.find((fn) => fn.chu_org_key === this.userSelectedChurchKey).church_name
        : ''
    },
    all_presenters_list() {
      let plist = []
      plist = this.all_presenters.map((el) => {
        let name = el.name + `${el.qualified ? '*' : ''}`
        return {
          value: el.ind_key,
          text: name,
        }
      })
      return plist
    },
    all_gideon_presenters_list() {
      let plist = []
      plist = this.all_gideon_presenters.map((el) => {
        let name = el.name + `${el.qualified ? '*' : ''}`
        return {
          value: el.ind_key,
          text: name,
        }
      })
      return plist
    },
  },
  components: {
    AddServiceTime: AddServiceTime,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    PersonSearch: PersonSearch,
    PresentationTime: PresentationTime,
    ToggleArrow: ToggleArrow,
    VueTimepicker: VueTimepicker,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

.g-row {
  margin-bottom: 20px;

  .g-col-2 {
    max-width: 330px !important;

    input[type='number'] {
      max-width: 100px;
    }
  }
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.err_message {
  color: #dd0000;
  font-weight: 600;
}

.form_label {
  font-size: 14px !important;
  font-weight: 600;
}

input[type='number'] {
  height: auto;
  max-width: 135px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker input // .vue__time-picker input

  {
  border: 0px solid #373737;
  padding: 10px 20px !important;
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 700;
  text-transform: uppercase;
}

.vue__time-picker {
  border: 0;
  width: 100%;
  font-weight: 700;
}

.vue__time-picker input.display-time {
  border: 1px solid #d2d2d2;
  width: 100%;
  font-weight: 600;
  padding-left: 20px;
}

.timepicker {
  border: 1px solid #373737 !important;
  width: 18em !important;

  @include breakpoint(sm) {
    width: 100% !important;
  }
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  padding-left: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

.custom-control.custom-checkbox {
  padding-left: 1.5rem;
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  width: 45%;

  @include breakpoint(sm) {
    width: 100%;
  }

  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;

  @include breakpoint(mobile) {
    width: 100% !important;
  }
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

.sm-text-left {
  @include breakpoint(mobile) {
    text-align: left !important;
  }
}

.card-deck {
  @include breakpoint(mobile) {
    display: block;
  }
}

form select.form-control:nth-of-type(1) {
  @include breakpoint(mobile) {
    max-width: 55%;
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
